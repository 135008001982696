import styled from "styled-components";
import { theme } from "../styles/theme";
import showCaseLG from "../assets/images/showcase-lg.svg";
import showCaseMD from "../assets/images/showcase-md.svg";
import showCaseSM from "../assets/images/showcase-sm.svg";
import bannerFooterLG from "../assets/images/banner.png";
import bannerFooterMD from "../assets/images/bannerMD.png";
import bannerFooterSM from "../assets/images/bannerSM.png";
import Facebook from "../assets/icon/facebook.svg";
import Instagram from "../assets/icon/instagram.svg";
import Youtube from "../assets/icon/youtube.svg";
import Twitter from "../assets/icon/twitter.svg";
import logo from "../assets/images/logo.svg";
import Arrow from "../assets/icon/arrow_down.svg";
import { useEffect, useState } from "react";
import ModalCheckout from "../components/Modals/ModalCheckout";
import TransformaLogo from "../assets/images/transforma-logo.svg";
import NeoEnergia from "../assets/images/neo-energia.svg";
import api from "../services/api";
import DOMPurify from "dompurify";

export interface IEbook {
  id?: number;
  name?: string; 
  slug?: string;
  cover?: string;
  caption?: string;
  description: string | Node;
}

export interface IPage {
  id?: number;
  title: string | Node; 
  slug?: string;
  summary: string | Node;
  content: string | Node;
  status: string | Node;
}

export const Main = () => {
  const [feedbackOK, setfeedbackOK] = useState<boolean>(false);
  const [handleOpenModalCheckout, setHandleOpenModalCheckout] =
  useState<boolean>(false);
  
  const [useEbook, setUseEbook] = useState<string>("");

  const [listEbook, setListEbook] = useState<IEbook[]>([]);
  const [page, setPage] = useState<IPage[]>();
  const [pageOne, setPageOne] = useState<IPage[]>();

  const getEbooks = async () => {
    await api.get<IEbook[]>('ebooks').then(response => setListEbook(response.data));
  }

  const getPage = async (slug: string) => {
    await api.get<IPage[]>(`page/${slug}`).then(response => setPage(response.data));
  }

  const getPageOne = async (slug: string) => {
    await api.get<IPage[]>(`page/${slug}`).then(response => setPageOne(response.data));
  }
    

  useEffect(() => { 
    getEbooks();
    getPage('pcapacitacao-p-br-pdesenvolvimento-p-br-pimpacto-p');
    getPageOne('somos-uma-social-tech-especializada-em-produzir-engajamento-civico');
    
    if (feedbackOK) {
      setTimeout(() => {
        setfeedbackOK(false);
      }, 5000);
    }
  }, [feedbackOK]);

  if (!page) return null;
  if(!pageOne) return null;

  const handleOpenEbook = (ebook: string) => {
    setUseEbook(ebook);
    setHandleOpenModalCheckout(true);
  };

  return (
    <Container>
      <Header>
        <ul>
          <li>
            <a target="_blank" href="https://transformabrasil.com.br">
              <LogoTransforma src={logo} />
            </a>
          </li>
          <li>
            <DownloadEbook href="#ebooks"> Baixar meu e-book </DownloadEbook>
          </li>
        </ul>
      </Header>
      <ContainerShowcase>
        <ShowCaseDetails>
          <TitleDetails>
           <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(page[0].title)}}></div> 
          </TitleDetails>         
          <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(page[0].summary)}}></p>          
          <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(page[0].content)}}></div>          
          <a href="#ebooks">
            <ArrowDown>
              <img id="arrow-down" src={Arrow} alt="" />
            </ArrowDown>
          </a>
        </ShowCaseDetails>
      </ContainerShowcase>
      <TitleEbooks>
        <H1>E-books em destaque</H1>
        <P>Estes são nossos materiais mais recentes.</P>
      </TitleEbooks>
      <ContainerCards id="ebooks">
      {listEbook.map((data) => {
            return (
              <Card key={data.id}>
                <CardPic src={data.cover} alt={data.name} />
                <CardTitle>{data.name}</CardTitle>
                <CardSubtitle>{data.caption}</CardSubtitle>
                <CardComments>
                <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(data.description)}}></div>      
                </CardComments>
                <ButtonGetEbook
                  onClick={() => {
                    handleOpenEbook(`${data.slug}`);
                  }}
                >
                  Garantir meu E-book
                </ButtonGetEbook>
              </Card>
            )
        })}  
        
      </ContainerCards>
      <ContainerSocialTech>
        <ContentSocialTech>
          <SocialTechTitle>
              <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(pageOne[0].title)}}></div> 
          </SocialTechTitle>

          <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(pageOne[0].content)}}></div> 
          <br />

          <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(pageOne[0].summary)}}></p> 
          <br />

          <p> O voluntariado muda vidas! Vamos juntos?</p>
          <LogoDiv>
            <a href="https://transformabrasil.com.br/" target="_blank">
              <Img src={TransformaLogo} alt="transforma logo" />{" "}
            </a>
            <a
              href="https://www.neoenergia.com/pt-br/Paginas/default.aspx"
              target="_blank"
            >
              <Img src={NeoEnergia} alt="neo energia logo" />{" "}
            </a>
          </LogoDiv>
        </ContentSocialTech>
      </ContainerSocialTech>

      <Footer>
        <ul>
          <SocialMedia>
            <a href="https://www.facebook.com/transforma.br/" target="_blank">
              <img src={Facebook} alt="Facebook" />
            </a>
          </SocialMedia>
          <SocialMedia>
            <a
              href="https://www.instagram.com/transforma.brasil/"
              target="_blank"
            >
              <img src={Instagram} alt="Instagram" />
            </a>
          </SocialMedia>
          <SocialMedia>
            <a
              href="https://www.youtube.com/channel/UCS874ViYbmEx06W7n3C38fQ"
              target="_blank"
            >
              <img src={Youtube} alt="Youtube" />
            </a>
          </SocialMedia>
          <SocialMedia>
            <a href="https://twitter.com/transformabra" target="_blank">
              <img src={Twitter} alt="Twitter" />
            </a>
          </SocialMedia>
        </ul>
      </Footer>
      <ModalCheckout
        isOpen={handleOpenModalCheckout}
        onClose={setHandleOpenModalCheckout}
        selectedEbook={useEbook}
      />
    </Container>
  );
};

const Container = styled.main`
  margin: 0 auto;
`;
const Header = styled.header`
  margin: 0 auto;
  padding-top: 18px;

  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin: 0 auto;
  }
  @media screen and (max-width: 600px) {
    justify-content: space-evenly;
  }
`;

const LogoTransforma = styled.img`
  @media screen and (max-width: 600px) {
    width: 80%;
  }
`;

const DownloadEbook = styled.a`
  color: ${theme.colors.white};
  background-color: ${theme.colors.blue};
  font-family: "Rubik";
  font-weight: 500;
  padding: 16px 22px;
  border-radius: 6px;
  transition: all ease 0.2s;
  :hover {
    filter: brightness(1.1);
    cursor: pointer;
  }
  @media screen and (max-width: 400px) {
    padding: 12px 18px;
    font-size: 12px;
  }
  @media screen and (max-width: 300px) {
    display: none;
  }
`;

const ContainerShowcase = styled.section`
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  margin-top: 20px;
  width: 100%;
  height: 800px;
  margin-bottom: 80px;
  background-image: url(${showCaseLG});
  @media screen and (max-width: 1120px) {
    background-image: url(${showCaseMD});
    height: 900px;
  }

  @media screen and (max-width: 700px) {
    background-image: url(${showCaseSM});
    background-size: cover;
    background-position: bottom;
    height: 1200px;
    flex-direction: column;
  }
  @media screen and (max-width: 700px) {
    background-image: url(${showCaseSM});
    background-size: cover;
    background-position: bottom;
    height: 1000px;
    align-items: flex-start;
  }
`;

const ShowCaseDetails = styled.div`
  margin-top: 40px;

  margin-left: 100px;
  color: ${theme.colors.white};
  font-size: 18px;

  p {
    width: 45%;
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 10px;
  }

  @media screen and (max-width: 1200px) {
    margin-left: 20px;
    font-size: 16px;
  }
  @media screen and (max-width: 1120px) {
    width: 50%;

    p {
      width: 100%;
      font-size: 16px;
      line-height: 22px;
    }
  }
  @media screen and (max-width: 700px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 44px;
    color: ${theme.colors.white};
    font-size: 18px;
    width: 90%;

    p {
      width: 100%;
      font-size: 16px;
      line-height: 22px;
    }
  }
`;

const TitleDetails = styled.div`
  padding-bottom: 32px;
  p {
    font-size: 55px;
    font-weight: 700;

    width: 70%;
  }

  @media screen and (max-width: 1200px) {
    p {
      width: 100%;
      font-size: 40px;
    }
  }
`;

const ArrowDown = styled.div`
  padding-top: 20px;
  #arrow-down {
    animation: slid 1s ease-in-out infinite;
    width: 20px;
  }
  @keyframes slid {
    0%,
    100% {
      transform: translate(0, 0);
    }

    50% {
      transform: translate(0, 15px);
    }
  }
`;

const CardTopics = styled.div`
  h3 {
    text-align: center;
    color: ${theme.colors.blue};
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 32px;
  }
  ul {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 30px;
    li {
      display: flex;
      align-items: flex-start;
      gap: 16px;
      font-size: 18px;
      color: ${theme.colors.greyBluesh};
      img {
        margin-top: 5px;
      }
    }
  }
`;

const ContainerCards = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 40px;
  width: 90%;
  margin: 0 auto;
  padding-top: 40px; 
  position: relative;
  flex-wrap: wrap;
  margin-bottom: 80px;
  @media screen and (max-width: 1120px) {
    flex-direction: column;
    gap: 200px;
    align-items: center;
  }
`;

const Card = styled.div`
  display: flex;
  padding-left: 45px;
  padding-right: 45px;
  width: 100%;
  max-width: 530px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  border: 1px solid #e6eaf6;
  box-sizing: border-box;
  box-shadow: 0px 10px 30px rgba(13, 51, 32, 0.1);
  border-radius: 8px;
  margin-top: 126px;
  min-height: 723px; 
`;
const CardPic = styled.img`
  background-color: ${theme.colors.lime};
  max-width: 300px;
  border-radius: 8px;
  margin-top: -130px;
  margin-bottom: 32px;
  min-height: 426px;
`;
const CardTitle = styled.h3`
  color: ${theme.colors.blue};
  font-size: 32px;
  font-weight: 500;
  text-align: center;
`;
const CardSubtitle = styled.p`
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: ${theme.colors.greyBluesh};
  margin-bottom: 32px;
  @media screen and (max-width: 600px) {
    text-align: center;
    padding-top: 10px;
  }
`;
const CardComments = styled.div`
  color: ${theme.colors.blue};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  margin-bottom: 32px;
`;

const ButtonGetEbook = styled.a`
  text-align: center;
  width: 90%;
  padding: 16px 20px;
  background-color: ${theme.colors.green};
  color: ${theme.colors.white};
  border-radius: 5px;
  font-weight: 500;
  margin-bottom: 50px;
  transition: all ease 0.2s;
  :hover {
    filter: brightness(1.1);
    cursor: pointer;
  }
`;

const TitleEbooks = styled.div``;

const H1 = styled.h1`
  font-family: "Rubik", sans-serif;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  letter-spacing: -1px;
  color: #495eec;
`;

const P = styled.p`
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #8798ad;
  padding-top: 16px;
`;

const ContainerSocialTech = styled.section`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  background-image: url(${bannerFooterLG});
  height: 600px;
  display: flex;
  align-items: center;

  @media screen and (min-width: 1890px) {
    height: 1000px;
  }
  @media screen and (max-width: 1190px) {
    background-image: url(${bannerFooterMD});
    background-position: bottom;
    background-size: cover;
    height: 900px;
    align-items: flex-start;
  }
  @media screen and (max-width: 520px) {
    background-image: url(${bannerFooterSM});
    height: 1100px;
    background-size: contain;
  }
`;
const SocialTechTitle = styled.h3`
  color: #495eec;
  font-size: 40px;
  font-weight: 400;
  line-height: 48px;
  width: 40%;
  margin-top: 20px;
  margin-bottom: 20px;
  @media screen and (max-width: 1190px) {
    width: 80%;
  }
  @media screen and (max-width: 690px) {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    width: 80%;
  }
`;
const ContentSocialTech = styled.div`
  color: ${theme.colors.greyBluesh};
  font-size: 18px;
  margin-left: 40px;
  width: 100%;
  p {
    width: 40%;
    font-size: 16px;
    line-height: 22px;
  }

  @media screen and (max-width: 1190px) {
    margin: 0 auto;
    width: 80%;
    font-size: 16px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      width: 80%;
      font-size: 16px;
      line-height: 22px;
    }
  }

  @media screen and (max-width: 900px) {
    width: 100%;
  }
`;

const Footer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
  margin: 0 auto;
  ul {
    display: flex;
    align-items: center;
    gap: 16px;
  }
`;

const SocialMedia = styled.li`
  display: flex;
  align-items: center;
`;

const LogoDiv = styled.div``;

const Img = styled.img`
  padding: 26px 24px 20px 0px;
`;
