import { Form, Formik } from "formik";
import React, { SetStateAction, useEffect, useState } from "react";
import styled from "styled-components";
import { theme } from "../../styles/theme";
import { state } from "../../utils/state";
import Input from "../Inputs/Input";
import InputSelect from "../Inputs/InputSelect";
import { phoneMask } from "../../utils/mask";
import Success from "../../assets/icon/sucess.svg";
import * as yup from "yup";
import { toast } from "react-toastify";
import Spinner from "../Inputs/Spinner";
import api from "../../services/api";

type Props = {
  isOpen: boolean;
  onClose: React.Dispatch<SetStateAction<boolean>>;
  selectedEbook: string;
};

type FormValues = {
  name: string;
  email: string;
  phone: string;
  ebook_option: string;
  state: string;
  term: boolean | string;
};
type StateProps = {
  uf: string;
};

const initialValues: FormValues = {
  name: "",
  email: "",
  phone: "",
  state: "",
  term: false,
  ebook_option: "de-repente-voluntario",
};

const FormSchema = yup.object().shape({
  name: yup.string().required("Campo Obrigatório!"),
  email: yup.string().email("Email Inválido 🤔").required("Campo Obrigatório!"),
  phone: yup.string().required("Campo Obrigatório!"),
  state: yup.string().required("Campo Obrigatório!"),
  term: yup.string().required("Campo Obrigatório!"),
  ebook_option: yup.string(),
});

const ModalCheckout: React.FC<Props> = ({
  isOpen,
  onClose,
  selectedEbook,
}: Props) => {
  const [checked, isChecked] = useState<boolean>(false);
  const [feedbackOK, setfeedbackOK] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [districts] = useState<StateProps[]>(state);

  useEffect(() => {
    if (feedbackOK) {
      setTimeout(() => {
        onClose(false);
        setfeedbackOK(false);
      }, 5000);
    }
  }, [feedbackOK]);

  const handleSubmit = async (values: FormValues) => {
    setIsLoading(true);
    const data: FormValues = {
      name: values.name,
      email: values.email,
      phone: values.phone,
      ebook_option: selectedEbook,
      term: values.term.toString(),
      state: values.state,
    };
   
    await api
      .post(`ebook/${selectedEbook}`, data)
      .then((res: any) => {
        if (res) {
          setfeedbackOK(true);
        } else {
          toast.warning("Algo deu errado 🤔");
        }
      });
    setIsLoading(false);
  };

  return (
    <ScreenContainer isVisible={isOpen}>
      <Container>
        {isOpen && (
          <>
            <Overlay onClick={() => onClose(false)} />
            <ContainerForm id="form">
              <i className="fas fa-times" onClick={() => onClose(false)}></i>
              {feedbackOK ? (
                <SucessFeedback className="animate__animated animate__fadeIn">
                  <DivFlex>
                    <Content>
                      <img src={Success} alt="" />
                      <h1>Solicitação submetida com sucesso</h1>
                      <p>
                        Em breve você receberá um retorno sobre <br /> seus
                        e-books. Fique atento ao seu e-mail.
                      </p>
                    </Content>
                  </DivFlex>
                </SucessFeedback>
              ) : (
                <>
                  <i
                    className="fas fa-times"
                    onClick={() => onClose(false)}
                  ></i>
                  <FormContainer className="animate__animated animate__fadeIn">
                    <h3>Garanta o seu e-book</h3>
                    <p>
                      Para quem quer aprender mais sobre voluntariado e projetos
                      sociais.
                    </p>
                    <FormContent>
                      <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        validationSchema={FormSchema}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                        }) => (
                          <Form onSubmit={handleSubmit}>
                            <Input
                              placeholder="Seu nome"
                              value={values.name}
                              onChange={handleChange("name")}
                              onBlur={handleBlur("name")}
                              error={
                                touched.name && errors.name
                                  ? errors.name
                                  : undefined
                              }
                              type="text"
                              name="name"
                              autoComplete="name"
                              autoCapitalize="words"
                            />
                            <Input
                              placeholder="Seu e-mail"
                              value={values.email}
                              onChange={handleChange("email")}
                              onBlur={handleBlur("email")}
                              error={touched.email ? errors.email : undefined}
                              type="email"
                              name="email"
                              autoComplete="off"
                            />
                            <Input
                              placeholder="Seu telefone"
                              value={phoneMask(values.phone)}
                              onChange={handleChange("phone")}
                              onBlur={handleBlur("phone")}
                              error={touched.phone ? errors.phone : undefined}
                              type="tel"
                              name="phone"
                              maxLength={15}
                            />
                            <InputSelectWrapper>
                              <InputSelect
                                placeholder="Selecione seu estado"
                                value={values.state}
                                onChange={handleChange("state")}
                                onBlur={handleBlur("state")}
                                error={touched.state ? errors.state : undefined}
                                name="state"
                              >
                                <option>Selecione seu estado</option>

                                {districts.map(
                                  (item: StateProps, idx: number) => {
                                    return (
                                      <option value={item.uf} key={idx}>
                                        {item.uf}
                                      </option>
                                    );
                                  }
                                )}
                              </InputSelect>
                            </InputSelectWrapper>
                            <CheckboxContainer>
                              <CheckboxContent>
                                <DivFlexLabel>
                                  <InputCheckbox
                                    wrapperStyle={{ flex: "none" }}
                                    onChange={handleChange("term")}
                                    onBlur={handleBlur("term")}
                                    type="checkbox"
                                    name="term"
                                    id="term"
                                    value="true"
                                    disabled={checked}
                                  />
                                  <Label htmlFor="term">
                                    Desejo receber emails e informações sobre a
                                    plataforma.
                                  </Label>
                                </DivFlexLabel>
                              </CheckboxContent>
                              <Button
                                disabled={
                                  isLoading === true ||
                                  values.name === "" ||
                                  values.email === "" ||
                                  values.term === false ||
                                  values.phone === ""
                                }
                                type="submit"
                              >
                                {isLoading ? <Spinner /> : "Baixar meu E-Book"}
                              </Button>
                            </CheckboxContainer>
                          </Form>
                        )}
                      </Formik>
                    </FormContent>
                  </FormContainer>
                </>
              )}
            </ContainerForm>
          </>
        )}
      </Container>
    </ScreenContainer>
  );
};

const ScreenContainer = styled.div<{ isVisible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #00000080;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  pointer-events: ${(props) => (props.isVisible ? "auto" : "none")};
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  padding: 0px 32px 0px 32px;
  text-align: center;
  border-radius: 6px;
  i {
    color: black;
    font-weight: bold;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    margin-right: 25px;
    margin-top: 26px;
    display: block;
    padding: 4px 8px;
    background: #fff;
    border-radius: 5px;
  }
`;

const Overlay = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
`;

const ContainerForm = styled.section`
  max-width: 730px;
  padding-top: 50px;
  padding-bottom: 30px;
  background-color: ${theme.colors.green};
  position: relative;
  z-index: 2;
  margin: 0 auto;
  border-radius: 6px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  h4 {
    font-weight: 700;
    font-size: 16px;
    color: ${theme.colors.blue};

    margin-bottom: 24px;
  }
  @media screen and (max-width: 590px) {
    flex-direction: column;
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  max-width: 520px;

  h3 {
    font-weight: 400;
    font-size: 32px;
    color: ${theme.colors.blue};
    text-align: center;
    margin-bottom: 8px;
  }
  p {
    color: ${theme.colors.white};
    font-weight: 400;
    font-size: 24px;
    margin-bottom: 30px;
    text-align: center;
  }
  @media screen and (max-width: 400px) {
    h3 {
      font-size: 24px;
    }
    p {
      font-size: 16px;
      margin-bottom: 20px;
    }
  }
`;

const FormContent = styled.div`
  width: 100%;
  @media screen and (max-width: 590px) {
    width: 90%;
  }
`;

const Button = styled.button`
  max-width: 530px;
  height: 56px;
  width: 100%;
  border: none;
  border-radius: 12px;
  background-color: ${theme.colors.blue};
  color: ${theme.colors.white};
  font-weight: 500;
  font-size: 16px;
  margin-top: 30px;
  cursor: pointer;

  :disabled {
    opacity: 0.7;
    pointer-events: none;
  }
`;

const CheckboxContent = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: 590px) {
    flex-direction: column;
  }
`;

const Label = styled.label`
  font-size: 16px;
  font-weight: normal;
  color: ${theme.colors.blue};
  @media screen and (max-width: 400px) {
    font-size: 14px;
    padding-top: 8px;
    text-align: left;
  }
`;
const DivFlexLabel = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: -10px;
  @media screen and (max-width: 590px) {
    width: 100%;
  }
`;

const InputCheckbox = styled(Input)`
  box-sizing: border-box;
  box-shadow: 2px 2px 0px ${theme.colors.green};
  background: ${theme.colors.green};
  margin: 12px;
  margin-top: 10px;
  margin-right: 25px;
  &[type="checkbox"]::before {
    content: " ";
    border-radius: 4px;
    width: 24px;
    height: 24px;
    position: absolute;
    background: ${theme.colors.green};
    border: 2px solid ${theme.colors.white};
  }
  &[type="checkbox"]:checked::before {
    background: ${theme.colors.blue};
    border: 2px solid ${theme.colors.blue};
  }
  &[type="checkbox"]:checked::after {
    content: " ";
    width: 11px;
    height: 6px;
    position: absolute;
    margin-top: 7.8px;
    margin-left: 7px;
    border-radius: 1px;
    transform: rotate(-52deg);
    /* border left */
    border-left-style: solid;
    border-left-width: 3px;
    border-left-color: ${theme.colors.white};

    /* border bottom */
    border-bottom-style: solid;
    border-bottom-width: 3px;
    border-bottom-color: ${theme.colors.white};
  }
`;

const DivFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  @media screen and (max-width: 590px) {
    flex-direction: column;
    gap: 0;
    > div {
      width: 100%;
    }
  }
`;
const Content = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin: 100px auto;
  h1 {
    margin-top: 40px;
    margin-bottom: 16px;
    font-weight: 500;
    font-size: 40px;
    line-height: 48px;
    text-align: center;
    color: ${theme.colors.white};
    @media screen and(max-width: 590px) {
      font-size: 24px;
      line-height: 28px;
    }
  }
`;
const InputSelectWrapper = styled.div`
  display: flex;
  flex: 0.5;
  flex-direction: column;
  margin-bottom: 16px;
  @media screen and (max-width: 590px) {
    flex-direction: column;
    width: 100%;
  }
`;
const SucessFeedback = styled.div`
  width: 100%;
  background-color: ${theme.colors.green};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
`;

export default ModalCheckout;
