import Cleave from "cleave.js/react";
import { CleaveOptions } from "cleave.js/options";
import { Fragment, InputHTMLAttributes } from "react";
import styled, { css, CSSProperties } from "styled-components";
import { theme } from "../../styles/theme";

export type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  error?: string;
  wrapperStyle?: CSSProperties;
  component?: JSX.Element;
  mask?: CleaveOptions;
  renderLabel?: (props: InputProps) => JSX.Element;
  renderInput?: (props: InputProps) => JSX.Element;
};

const Input = (inputProps: InputProps): JSX.Element => {
  const {
    label,
    wrapperStyle,
    error,
    mask,
    renderInput,
    renderLabel,
    ...props
  } = inputProps;

  const renderEntry = () => {
    if (renderInput) {
      return renderInput(inputProps);
    }

    if (mask) {
      return <StyledMaskInput error={error} options={mask} {...props} />;
    }

    return <StyledInput error={error} {...props} />;
  };

  return (
    <Wrapper style={wrapperStyle}>
      {renderLabel ? renderLabel(inputProps) : label && <Label>{label}</Label>}
      <SpacerDown />
      {renderEntry()}

      {error && (
        <Fragment>
          <SpacerUp />
          <FormFeedback>{error}</FormFeedback>
        </Fragment>
      )}
    </Wrapper>
  );
};

export const InputWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const InputRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 24px;
  @media screen and (max-width: 400px) {
    margin-bottom: 16px;
  }
`;

const Label = styled.label(
  () => css`
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: normal;
    line-height: 24px;
    color: ${theme.colors.grey};
  `
);

const InputStyle = css<InputProps>`
  padding: 16px;
  outline: none;
  border: 1.2px solid ${theme.colors.white};
  border-radius: 8px;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  transition: border-color ease 0.5s;
  will-change: border-color;
  color: ${theme.colors.grey};

  &:focus {
    border-color: ${theme.colors.blue};
  }

  ${(props) =>
    props.error &&
    css`
      border-color: ${theme.colors.errorBorder};
    `}

    @media screen and (max-width: 400px) {
      padding: 12px;
      font-size: 12px;
      line-height: 16px;
    }
`;

const StyledInput = styled.input<InputProps>`
  ${InputStyle};
`;

const StyledMaskInput = styled(Cleave)`
  ${InputStyle};
`;

const FormFeedback = styled.div`
    width: 100%;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    border-radius: 10px;
    background-color: ${theme.colors.feedbackErrorBg};
    color: ${theme.colors.feedbackErrorFontColor};
    width: 180px;
    padding: 10px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @media screen and (max-width: 400px) {
      font-size: 12px;
      line-height: 16px;
      font-weight: 500;
      width: 120px;
    }
  `
;
const SpacerDown = styled.div`
  padding-top: 8px;
`;
const SpacerUp = styled.div`
  padding-top: 8px;
`;
export default Input;
