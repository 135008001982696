import { Fragment, SelectHTMLAttributes } from "react";
import styled, { css, CSSProperties } from "styled-components";
import { theme } from "../../styles/theme";
import ArrowDown from '../../assets/icon/arrowDownSelect.svg'

type SelectOption = {
  label: string;
  value: string;
};

type SelectProps = SelectHTMLAttributes<HTMLSelectElement> & {
  label?: string;
  error?: string;
  wrapperStyle?: CSSProperties;
  select?: boolean;
  selectOptions?: SelectOption[];
  renderLabel?: (props: SelectProps) => JSX.Element;
  renderSelect?: (props: SelectProps) => JSX.Element;
};

const InputSelect = (inputProps: SelectProps): JSX.Element => {
  const { label, wrapperStyle, error, renderSelect, renderLabel, ...props } =
    inputProps;

  return (
    <Wrapper style={wrapperStyle}>
      {renderLabel ? renderLabel(inputProps) : label && <Label>{label}</Label>}
      <SpacerDown />
      {renderSelect ? (
        renderSelect(inputProps)
      ) : (
        <StyledSelect error={error} {...props} />
      )}
      <EntryIcon
      src={ArrowDown}
        className="fas fa-chevron-down"
        hasLabel={label !== undefined}
      />
      {error && (
        <Fragment>
          <SpacerUp />
          <FormFeedback>{error}</FormFeedback>
        </Fragment>
      )}
    </Wrapper>
  );
};

export default InputSelect;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
`;
const Label = styled.label(
  () => css`
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: normal;
    line-height: 24px;

    color: ${theme.colors.grey};
  `
);

const StyledSelect = styled.select<SelectProps>`
  padding: 16px;
  padding-right: 30px;

  outline: none;

  border: 1.2px solid ${theme.colors.white};
  border-radius: 8px;

  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;

  transition: border-color ease 0.5s;
  will-change: border-color;
  color: ${theme.colors.grey};

  appearance: none;

  transition: border-color ease 0.5s;
  will-change: border-color;
  &:focus {
    border-color: ${theme.colors.grey};
  }

  ${(props) =>
    props.error &&
    css`
      border-color: ${theme.colors.errorBorder};
    `}
    @media screen and (max-width: 400px) {
      padding: 12px;
      font-size: 12px;
      line-height: 16px;
    }
    
`;

const FormFeedback = styled.div(
  () => css`
    width: 100%;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    border-radius: 10px;
    background-color: ${theme.colors.feedbackErrorBg};
    color: ${theme.colors.feedbackErrorFontColor};
    width: 160px;
    padding: 10px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `
);

const EntryIcon = styled.img<{ hasLabel: boolean }>`
  position: absolute;
  top: ${(props) => (props.hasLabel ? 55 : 30)}px;
  right: 16px;
  color: ${theme.colors.grey};
  align-items: center;
  @media screen and (max-width: 400px) {
    top: ${(props) => (props.hasLabel ? 55 : 25)}px;
  }
`;
const SpacerDown = styled.div`
  padding-top: 6px;
`;
const SpacerUp = styled.div`
  padding-top: 6px;
`;
