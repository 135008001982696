import axios from "axios";


const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
   //  token: String(process.env.REACT_APP_API_TOKEN),
   //  entity: String(process.env.REACT_APP_API_ENTITY),
 }

 const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers,
    proxy: false,
 })
 
 export default api
